<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">

        <svg xmlns="http://www.w3.org/2000/svg"
             version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>

      <Particles id="tsparticles"
                 :options="{
          particles: {
            number: {
              value: 90,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: '#ffffff',
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 0,
                color: '#000000',
              },
              polygon: {
                nb_sides: 5,
              },
              image: {
                src: 'img/github.svg',
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 0.8,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0,
                sync: false,
              },
            },
            size: {
              value: 4,
              random: true,
              anim: {
                enable: false,
                speed: 4,
                size_min: 0.2,
                sync: false,
              },
            },
            line_linked: {
              enable: false,
              distance: 150,
              color: '#ffffff',
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 2,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: true,
                mode: 'bubble',
              },
              onclick: {
                enable: true,
                mode: 'repulse',
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 4,
                duration: 2,
                opacity: 0.8,
                speed: 3,
              },
              repulse: {
                distance: 200,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
          config_demo: {
            hide_card: false,
            background_color: '#b61924',
            background_image: '',
            background_position: '50% 50%',
            background_repeat: 'no-repeat',
            background_size: 'cover',
          },
        }"/>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img
                    src="@/assets/images/svg/logo_easyavpre_branco.svg"
                    alt=""
                    height="20"
                  />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">

            <div class="card mt-4" id="card_email" ref="card_email">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Esqueçeu a senha ?</h5>
                  <p class="text-muted">Informe seu email para recuperação</p>

                   <lottie
                    class="avatar-xl"
                      colors="primary:#0ab39c,secondary:#405189"
                      :options="defaultOptions"
                      :height="120"
                      :width="120"
                    />

                </div>

                <div class="p-2">
                  <b-alert
                    v-model="isResetError"
                    class="mb-4"
                    variant="danger"
                    dismissible
                    >{{ error }}</b-alert
                  >
                  <form @submit.prevent="tryToReset">
                    <div class="mb-4 text-center">
                      <label class="form-label">E-mail</label>
                      <input
                        type="email"
                        v-model="email"
                        class="form-control"
                        id="email"
                        :class="{ 'is-invalid': submitted && v$.email.$errors }"
                        placeholder="informe o email da sua conta"
                      />
                      <div
                        v-for="(item, index) in v$.email.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>

                    <div class="text-center mt-4">
                      <button class="btn btn-success w-100" type="button"
                      @click="RecuperarConta">
                        Enviar código de recuperação
                      </button>
                    </div>
                  </form>
                  <!-- end form -->
                </div>
              </div>
              <!-- end card body -->
            </div>

            <div class="card mt-4 d-none" id="card_code" ref="card_code" >
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Informe o código</h5>
                  <p class="text-muted">
                    Foi enviado um e-mail com o código de recuperação da sua conta,
                    verique sua caixa de entrada/spam.
                  </p>

                </div>

                <div class="p-2">


                    <div class="mb-4 text-center">
                      <label class="form-label" for="codigo">Código</label>
                      <input
                        type="text"
                        v-model="codigo"
                        class="form-control"
                        id="codigo"
                        v-mask="'#########'"
                        maxlength="8"
                        minlength="8"
                        placeholder="informe o código recebido no e-mail"
                      />
                      <span v-html="help_card_code" ref="help_card_code" class="text-danger"></span>

                    </div>

                    <div class="text-center mt-4">
                      <button class="btn btn-success w-100" type="button"
                      @click="VerificarCodigo">
                        Verificar código
                      </button>
                    </div>

                  <!-- end form -->
                </div>
              </div>
              <!-- end card body -->
            </div>

            <div class="card mt-4 d-none" id="card_password" ref="card_password" >
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Nova senha</h5>
                  <p class="text-muted">
                    Informe a nova senha pra acesso a sua conta.
                  </p>

                </div>

                <div class="p-2">


                    <div class="mb-4 text-center">
                      <label class="form-label" id="password">Nova senha</label>
                      <input
                        type="password"
                        v-model="password"
                        class="form-control"
                        minlength="8"
                        id="password"
                        placeholder=""
                      />

                    </div>
                  <div class="mb-4 text-center">
                    <label class="form-label" id="repassword">Nova senha novamente</label>
                    <input
                        type="password"
                        v-model="repassword"
                        class="form-control"
                        minlength="8"
                        id="repassword"
                        placeholder=""
                    />

                  </div>

                    <div class="text-center mt-4">
                      <button class="btn btn-success w-100" type="button"
                      @click="AtualizarSenha">
                        Salvar
                      </button>
                    </div>

                  <!-- end form -->
                </div>
              </div>
              <!-- end card body -->
            </div>

            <div class="card mt-4 d-none" id="card_finish" ref="card_finish" >
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Pronto</h5>
                  <p class="text-muted">
                    Senha alterado com sucesso
                  </p>

                </div>

                <div class="p-2">

                    <div class="text-center mt-4">
                      <router-link
                          class="fw-semibold text-primary text-decoration-underline"
                          to="/login">
                        Acessar EASY AV PRÉ
                      </router-link>
                    </div>

                  <!-- end form -->
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div class="mt-4 text-center">
              <p class="mb-0">
                voltar para a página anterior
                <router-link
                  to="/login"
                  class="fw-semibold text-primary text-decoration-underline"
                >
                  Clique aqui
                </router-link>
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} EASY UX
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>
<script>
import { authMethods } from "@/state/helpers";
import appConfig from "../../../app.config";
import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/rhvddzym.json";
import axios from "axios";
import {mask} from 'vue-the-mask'

export default {
  directives: {mask},
  components: {
    lottie: Lottie ,


  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    email: {
      required: helpers.withMessage("Email é obrigatório para recuperação", required),
      email: helpers.withMessage("Por favor informe o e-mail", email),
    },
  },
  page: {
    title: "Recuperar conta",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "",
      codigo: "",
      repassword: "",
      help_card_code: "",
      password: "",
      card_code : '',
      email_code : '',
      card_password : '',
      card_finish : '',
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      defaultOptions: { animationData: animationData },
    };
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          this.tryingToReset = true;
          // Reset the authError if it existed.
          this.error = null;
          return (
              this.resetPassword({
                email: this.email,
              })
                  // eslint-disable-next-line no-unused-vars
                  .then((token) => {
                    this.tryingToReset = false;
                    this.isResetError = false;
                  })
                  .catch((error) => {
                    this.tryingToReset = false;
                    this.error = error ? error : "";
                    this.isResetError = true;
                  })
          );
        }
      }
    },
    async RecuperarConta() {
      try {
        this.submitted = true;
        this.v$.$touch();
        if (this.v$.$invalid) {
          return;
        }

        const result = await axios.post(process.env.VUE_APP_API + '/recuperarconta/', {
          email: this.email
        })

        if(result.data.status){

          this.$refs.card_email.classList.add('d-none');
          this.$refs.card_password.classList.add('d-none');
          this.$refs.card_code.classList.remove('d-none');

          return;
        }

      }catch (e){
        console.log(e);
      }

    },
    async VerificarCodigo() {
      try {

        const result = await axios.post(process.env.VUE_APP_API + '/resgateconta/', {
          codigo: this.codigo
        })

        if(result.data.status){
          this.$refs.card_code.classList.add('d-none');
          this.$refs.card_password.classList.remove('d-none');

        }

      }catch (e){
        this.help_card_code = (e.response.data.msg);
      }

    },
    async AtualizarSenha() {
      try {

        const result = await axios.post(process.env.VUE_APP_API + '/atualizarsenha/', {
          email: this.email,
          password: this.password,
          repassword: this.repassword,
          codigo: this.codigo
        })
        console.log(result.data);

        if(result.data.status){
          this.$refs.card_password.classList.add('d-none');
          this.$refs.card_finish.classList.remove('d-none');

        }


      }catch (e){
        console.log(e);
      }

    },
  },
};
</script>
